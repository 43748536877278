

















































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Inject() vm!: CommunityDetailViewModel
}
